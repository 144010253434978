<template>
  <div class="statistics">
    <div class="sta-data">
      <el-row class="title">用户及金币数据</el-row>
      <el-row>
        <el-col :span="4">
          <div class="user" style="background: #409EFF;">
            <span class="quantity">{{ dataCount.registerUserCount }}</span>
            <span class="user-title">累计注册用户</span>
          </div>
        </el-col>
        <el-col :span="4">
          <div class="user" style="background: #67C23A;">
            <span class="quantity">{{ dataCount.attestUserCount }}</span>
            <span class="user-title">累计认证用户</span>
          </div>
        </el-col>
        <el-col :span="4">
          <div class="user" style="background: #E6A23C;">
            <span class="quantity">{{ dataCount.superAgentCount }}</span>
            <span class="user-title">累计超能经纪人</span>
          </div>
        </el-col>
        <el-col :span="4">
          <div class="user" style="background: #F56C6C;">
            <span class="quantity">{{ dataCount.rechargeCoinCount }}</span>
            <span class="user-title">累计充值高能金币数</span>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="sta-data">
      <el-row class="title">今日用户及金币数据</el-row>
      <el-row>
        <el-col :span="4">
          <div class="user" style="background: #409EFF;">
            <span class="quantity">{{ dataCount.dayRegisterUserCount }}</span>
            <span class="user-title">今日新增用户数</span>
          </div>
        </el-col>
        <el-col :span="4">
          <div class="user" style="background: #67C23A;">
            <span class="quantity">{{ dataCount.dayAttestUserCount }}</span>
            <span class="user-title">今日新增认证用户数</span>
          </div>
        </el-col>
        <el-col :span="4">
          <div class="user" style="background: #E6A23C;">
            <span class="quantity">{{ dataCount.daySuperAgentCount }}</span>
            <span class="user-title">今日新增高能经纪人</span>
          </div>
        </el-col>
        <el-col :span="4">
          <div class="user" style="background: #F56C6C;">
            <span class="quantity">{{ dataCount.dayRechargeCoinCount }}</span>
            <span class="user-title">今日充值高能金币数</span>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="sta-data">
      <el-row class="title">奖励数据</el-row>
      <el-row>
        <el-col :span="4">
          <div class="user" style="background: #409EFF;">
            <span class="quantity">{{ dataCount.unlockingReward }}</span>
            <span class="user-title">累计解锁返佣</span>
          </div>
        </el-col>
        <el-col :span="4">
          <div class="user" style="background: #67C23A;">
            <span class="quantity">{{ dataCount.unlockedReward }}</span>
            <span class="user-title">累计被解锁返佣</span>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="sta-data">
      <el-row class="title">礼品卡数据</el-row>
      <el-row>
        <el-col v-if="dataCount.role === 1" :span="4">
          <div class="user" style="background: #409EFF;">
            <span class="quantity">{{ dataCount.currencyCardCount }}</span>
            <span class="user-title">通用礼品卡总数</span>
          </div>
        </el-col>
        <el-col v-if="dataCount.role === 1" :span="4">
          <div class="user" style="background: #67C23A;">
            <span class="quantity">{{ dataCount.currencyCardUsed }}</span>
            <span class="user-title">通用礼品卡使用数</span>
          </div>
        </el-col>
        <el-col :span="4">
          <div class="user" style="background: #E6A23C;">
            <span class="quantity">{{ dataCount.businessCardCount }}</span>
            <span class="user-title">商务礼品卡总数</span>
          </div>
        </el-col>
        <el-col :span="4">
          <div class="user" style="background: #F56C6C;">
            <span class="quantity">{{ dataCount.businessCardUsed }}</span>
            <span class="user-title">商务礼品卡使用数</span>
          </div>
        </el-col>
        <el-col :span="4">
          <div class="user" style="background: pink;">
            <span class="quantity">{{ dataCount.highEnergyGiftCardCount }}</span>
            <span class="user-title">高能礼品卡总数</span>
          </div>
        </el-col>
        <el-col :span="4">
          <div class="user" style="background: #303133;">
            <span class="quantity">{{ dataCount.highEnergyGiftCardUseCount }}</span>
            <span class="user-title">高能礼品卡使用数</span>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { getDataCount } from '@/api/data'
export default {
  data() {
    return {
      dataCount: {}
    }
  },
  created() {
    this.getDataCount()
  },
  methods: {
    getDataCount() {
      getDataCount().then(res => {
        this.dataCount = res.data
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .statistics {
    padding: 50px;
    .sta-data {
      margin-bottom: 30px;
    }
    .title {
      font-size: 36px;
      margin-bottom: 16px;
    }
  }
  .user {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    height: 120px;
    background: #d7d7d7;
    justify-content: space-around;
    .quantity {
      font-size: 22px;
      color: #fff;
    }
    .user-title {
      font-size: 18px;
      color: #fff;
    }
  }
</style>
